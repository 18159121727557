<template>
  <div>
    <div class="div d-flex justify-content-between">
      <h5>Add Questions*</h5>
      <b-button
        variant="primary"
        @click="addQuestion()"
        class="mb-2 ml-auto"
        style="padding: 8px 25px"
      >
        <feather-icon icon="PlusCircleIcon" size="15" />
      </b-button>
    </div>
    <b-row
      v-for="(question, index) in form.questions"
      :key="index"
      class="align-items-end mb-1"
    >
      <b-col cols="12" xl="6" lg="5" style="min-height: 70px">
        <label>Questions</label>
        <Input
          type="text"
          placeholder="Your question"
          v-model="question.question"
        ></Input>
      </b-col>
      <!-- email -->
      <b-col cols="12" xl="4" lg="3" style="min-height: 70px">
        <label>Type</label>
        <Select v-model="question.answer_type" filterable>
          <Option :value="1">Number</Option>
          <Option :value="2">Text</Option>
        </Select>
      </b-col>

      <b-col cols="12" xl="2" lg="1" style="min-height: 50px; padding-left: 0">
        <b-button
          variant="danger"
          style="padding: 8px 25px"
          @click="removeQuestion(index)"
          class="w-100"
          :disabled="form.questions.length == 1"
        >
          <feather-icon icon="XCircleIcon" size="15" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import { HasError, AlertError } from "vform/src/components/bootstrap5";

export default {
  props: ["form"],

  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
  },
  methods: {
    addQuestion() {
      this.form.questions.push({
        company_job_id: null,
        question: null,
        answer_type: null,
      });
    },
    removeQuestion(index) {
      this.form.questions.splice(index, 1);
    },
  },
};
</script>
